<template>
  <button
    :title="$t(country)"
    class="country-list-item tw-appearance-none tw-bg-white tw-border-none tw-py-0 tw-px-4 md:tw-px-5 tw-cursor-pointer tw-relative"
    type="button"
  >
    <div class="tw-flex tw-justify-between">
      <div
        class="country-list-item__name__wrapper tw-relative tw-flex tw-items-center tw-mr-1 md:tw-mr-4"
        :class="{'new': countryObject.new}"
      >
        <ui-flag
          :country="country"
          class="tw-mr-1 md:tw-mr-3"
        />
        <div class="tw-hidden md:tw-block country-list-item__name">
          <span
            v-text="$t(country)"
            class="country-name"
          />
          <span
            v-if="$attrs.disabled"
            v-text="'*'"
          />
        </div>
      </div>

      <template
        v-if="sections.length > 0"
      >
        <ui-badge
          :class="{
            active: isSelected
          }"
          class="country-list-item__sections"
        >
          <i18n
            path="offer_list.search_engine.geo_selection.x_out_of_y"
            tag="span"
          >
            <template #x>
              <span
                class="tw-font-bold"
              >
                {{ $n(selectedSections.length) }}
              </span>
            </template>
            <template #y>
              <span>
                {{ $n(sections.length) }}
              </span>
            </template>
          </i18n>
        </ui-badge>
      </template>
      <template
        v-else
      >
        <b-form-checkbox
          :checked="isSelected"
          :id="'checkbox' + country"
          class="tw-mt-1 tw-pointer-events-none"
        />
      </template>

      <span
        v-if="!!$attrs.disabled"
        v-text="'*'"
        class="country-list-item__floating-disabled md:tw-hidden"
      />
    </div>
  </button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import useCountryLists from '@/composables/useCountryLists'

  import CtkChip from '@/components/CtkChip/index.vue'

  /**
   * Single button item on the country list.
   * @module component - CountryListItem
   * @param {string} country - Country iso-2 code
   * @param {Array} sections - The list of sections for the country. If the country
   * does not have subdivisions (Luxembourg, Andorra, ..), the list is empty.
   */
  export default defineComponent({
    name: 'CountryListItem',
    components: {
      CtkChip
    },
    props: {
      country: {
        type: String,
        required: true
      },
      sections: {
        type: Array,
        default: null
      },
      selectedSections: {
        type: [Array, Boolean],
        default: () => ([])
      }
    },
    computed: {
      /**
       * Returns true if one of the sections has been selected
       * @function isSelected
       * @returns {boolean}
       */
      isSelected () {
        return typeof this.selectedSections === 'boolean'
          ? this.selectedSections
          : this.selectedSections.length > 0
      },
      countryObject () {
        const { getCountryByIso2 } = useCountryLists()
        return getCountryByIso2(this.country)
      },
      availableCountries () {
        const { getAvailableCountries } = useCountryLists()
        return getAvailableCountries()
      }
    }
  })
</script>

<style lang="scss" scoped>
.country-list-item {
  color: $primary-text;
  height: 50px;
  outline: none;
  border-right: 1px solid $divider;
  transition: background-color 100ms;
}
.country-list-item:hover, .country-list-item.active {
  background-color: $light-gray;
}
.country-list-item:focus {
  background-color: $light-gray;
}
.country-list-item:disabled {
  opacity: 0.5;
}
.country-list-item:focus:hover, .country-list-item:focus.active {
  background-color: darken($light-gray, 5%);
}
.country-list-item__sections {
  background-color: $gray !important;
  color: $primary-text !important;
}
.country-list-item__sections.active {
  background-color: $primary !important;
  color: white !important;
}
.country-list-item__floating-disabled {
  position: absolute;
  top: 0.1rem;
  right: 0.25rem;
}
.country-list-item__new.ui-badge--sm {
  font-size: 0.65rem;
  line-height: 0.65rem;
  padding: 0.3rem 0.4rem;
}
.country-list-item__name__wrapper.new::after {
  --tw-bg-opacity: 1;
  background-color: rgba(150, 191, 49, var(--tw-bg-opacity));
  border-radius: 9999px;
  height: 6px;
  position: absolute;
  top: 4px;
  right: -2px;
  width: 6px;
  z-index: 1;
  content: '';
}
@media (min-width: 1024px) {
  .country-list-item__name__wrapper.new::after {
    top: 6px;
    right: -8px;
  }
}
.country-list-item:disabled, .country-list-item:disabled .country-list-item__sections {
  cursor: not-allowed;
  opacity: 0.5;
}
@media only screen and (min-width: $breakpoint-tablet) {
  .country-list-item {
    border-bottom: 1px solid $divider;
    border-right: none;
  }
}
</style>
